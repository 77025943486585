<script setup lang="ts">
import InputError from '@libero/ui-framework/InputError/InputError.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { classNames } from '@libero/utilities/class-names';
import { Checkbox as AntCheckbox } from 'ant-design-vue';
import { computed, onMounted, onUnmounted, useSlots } from 'vue';

interface Props {
  id: string;
  name: string;
  value: boolean;
  error?: string;
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  isIndeterminate?: boolean;
  onUpdate: (value: boolean) => void;
  onMount?: (onFocus?: () => void, shouldFocus?: boolean) => void;
  onUnmount?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  error: undefined,
  size: 'md',
  onMount: undefined,
  onUnmount: undefined,
});

const slots = useSlots();

const isEmpty = computed(() => !slots.default);

onMounted(() => {
  props.onMount?.();
});

onUnmounted(() => {
  props.onUnmount?.();
});
</script>

<template>
  <Stack :gap="0">
    <AntCheckbox
      :id="id"
      :name="name"
      class="checkbox"
      :class="classNames({ size, isEmpty })"
      :checked="value"
      :disabled="disabled"
      :indeterminate="isIndeterminate"
      :onUpdate:checked="onUpdate"
    >
      <span v-if="!isEmpty" class="checkbox-content">
        <span>
          <slot />
        </span>
      </span>
    </AntCheckbox>

    <InputError :message="error" />
  </Stack>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Checkbox/Checkbox.scss';
</style>
